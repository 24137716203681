import * as React from 'react';
import styles from './score.module.css';

const TITLES = ['Industry', 'Location', 'Clients since', 'Status'];

interface Props {
  list: ReadonlyArray<string>;
}

function Score({ list }: Props) {
  return (
    <div className={styles.container}>
      <div className="inner">
        {list.map((e, i) => (
          <dl key={e} className={styles.item}>
            <dt className={styles.title}>{TITLES[i]}</dt>
            <dd className={styles.valuation}>{e}</dd>
          </dl>
        ))}
      </div>
    </div>
  );
}

export default Score;
