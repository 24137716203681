import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Arrow from './arrow.inline.svg';
// styles
import clsx from 'clsx';
import styles from './breadcrumbs.module.css';

interface IData {
  to: string;
  title: string;
}

interface IProps {
  data: Array<IData>;
  className?: string;
}

const Breadcrumbs = ({ data, className }: IProps) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, []);

  return (
    <ul
      className={clsx(styles.container, className)}
      itemScope
      itemType="https://schema.org/BreadcrumbList"
    >
      {isMobile ? (
        <li
          className={styles.item}
          itemScope
          itemProp="itemListElement"
          itemType="https://schema.org/ListItem"
          key={data[data.length - 1].title}
        >
          <Link className={styles.link} to={data[data.length - 1].to}>
            <Arrow className={styles.arrow} />
            <span itemProp="name">{data[data.length - 1].title}</span>
          </Link>
          <meta itemProp="position" content="1" />
        </li>
      ) : (
        data.map((item, i) => (
          <li
            className={styles.item}
            key={item.to}
            itemScope
            itemProp="itemListElement"
            itemType="https://schema.org/ListItem"
          >
            <Link className={styles.link} to={item.to} itemProp="item">
              <Arrow className={styles.arrow} />
              <span itemProp="name">{item.title}</span>
            </Link>
            <meta itemProp="position" content={`${i + 1}`} />
          </li>
        ))
      )}
    </ul>
  );
};

export default Breadcrumbs;
