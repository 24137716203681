import * as React from 'react';
import clsx from 'clsx';
import styles from './header.module.css';
import Breadcrumbs from 'components/breadcrumbs';
import Identificators from 'components/googleAnalyticsIdentificators';

export interface ICaseHeader {
  headerTitle: string | React.ReactElement;
  subTitle: string;
  className?: string;
  contentClassName?: string;
  breadcrumbsClassName?: string;
  onCtaClick: () => void;
  coverImgUrl?: string;
}

function Header({
  headerTitle,
  subTitle,
  onCtaClick,
  className,
  contentClassName,
  breadcrumbsClassName,
  coverImgUrl,
}: ICaseHeader) {
  const breadcrumbsList = [
    { title: 'Home', to: '/' },
    { title: 'Cases', to: '/case-studies/' },
  ];

  return (
    <header
      className={clsx(styles.container, className)}
      style={{ '--bg-img': coverImgUrl ? `url(${coverImgUrl})` : '' } as React.CSSProperties}
    >
      <div className={clsx('inner', styles.wrapper)}>
        <div className={clsx(contentClassName, styles.content)}>
          <Breadcrumbs data={breadcrumbsList} className={clsx(styles.back, breadcrumbsClassName)} />
          <h1 className={styles.title}>{headerTitle}</h1>
          <p className={styles.subtitle}>{subTitle}</p>
          <button
            id={Identificators.WANT_DETAILS}
            className={clsx('btn btn_60 btn__white', styles.ctaBtn)}
            onClick={onCtaClick}
          >
            Want details?
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
