import React, { useRef } from 'react';
import clsx from 'clsx';
import styles from './layout.module.css';
import Layout from 'components/layout';
import Score from '../../score';
import Header, { ICaseHeader } from '../header';
import Booking, { IFeedback } from '../../../../feedback';
import Footer from 'components/layout/footer';
import { IMainMeta } from 'components/seo';

type IHeader = Omit<ICaseHeader, 'onCtaClick'>;

interface Props extends IHeader, IFeedback {
  children: React.ReactNode;
  layoutClassName?: string;
  score: ReadonlyArray<string>;
  navBtn: string;
  isRed?: boolean;
  metaData: IMainMeta;
}

function CaseLayout({
  children,
  layoutClassName,
  score,
  navBtn,
  isRed = false,
  metaData,
  ...props
}: Props) {
  const bookingRef = useRef<HTMLDivElement>(null);
  const handleClickCTA = () => {
    if (bookingRef.current !== null) {
      bookingRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };
  return (
    <Layout navBtnTextColor={navBtn} isRed={isRed} metaData={{ main: metaData }}>
      <Header {...props} onCtaClick={handleClickCTA} />
      <Score list={score} />
      <main className={clsx('main', styles.main, layoutClassName)}>{children}</main>
      <Booking
        title="Want to develop something similar?"
        subtitle="We are thrilled about the opportunity to provide software development services for your business"
        ctaRef={bookingRef}
        {...props}
      />
      <Footer />
    </Layout>
  );
}

export default CaseLayout;
